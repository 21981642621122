export default {
  async loadOffers({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/offers?page=${data.page}&per_page=${data.per_page}&status=${data.status}&projects=${data.projects}&order_by=${data.order_by}&search=${data.search}`,
      )
      .then(respo => {
        if (respo.status === 200) {
          commit('setOffers', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadOffersDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/offers/${applicantsId}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setOfferDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async changeOffersStatus({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/offers/${data.id}/status`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOfferDetails', respo.data.data.content);
          commit('successMessage', 'change_status_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async sendOffersSelection({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/offers/selection', data)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'send_offers_successfully');
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
};
