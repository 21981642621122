export default {
  async loadAdministrators({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/users?page=${data.page}&per_page=${data.per_page}&status=${data.status}&industry_preference=${data.industry_preference}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAdministrators', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadAdministratorsDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    console.log("applicantsId");
    await this._vm.$http
      .get(`super/users/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAdministratorDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async changeAdministratorsStatus({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/users/${data.id}/status`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAdministratorDetails', respo.data.data.content);
          commit('successMessage', 'change_user_status_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addAdministrators({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/users', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'user_saved_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
