import i18n from '@/libs/i18n';
import middleware from '../../middleware';
import middlewareAccountState from '../../middlewareAccountState';

export default [
  {
    path: ':role',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      middleware: middlewareAccountState,
    },
    children: [
      {
        path: 'not-found',
        name: 'page-not-found',
        component: () => import('@/views/pages/404.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'not-authorized',
        name: 'page-not-authorized',
        component: () => import('@/views/pages/403.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/dashboard.vue'),
        meta: {
          middleware: [middleware, middlewareAccountState],
        },
      },
      {
        path: 'notification',
        name: 'notification',
        component: () => import('@/views/pages/dashboard/notification.vue'),
        meta: {
          middleware: [middleware, middlewareAccountState],
        },
      },
      {
        path: 'users',
        component: {
          render(c) {
            return c('router-view');
          },
        },

        children: [
          {
            path: '',
            name: 'users',
            component: () => import('@/views/pages/users/index.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'employees',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'users-employees',
                component: () => import('@/views/pages/users/employees.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: ':id',
                name: 'users-employees-details',
                component: () =>
                  import('@/views/pages/users/employeeDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: 'business',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'users-business',
                component: () =>
                  import('@/views/pages/users/employeesSeekers.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: ':id',
                name: 'users-business-details',
                component: () =>
                  import('@/views/pages/users/employeeSeekerDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: 'new-accounts',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'users-new-accounts',
                component: () => import('@/views/pages/users/newAccounts.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'orders',
        component: {
          render(c) {
            return c('router-view');
          },
        },

        children: [
          {
            path: '',
            name: 'orders',
            component: () => import('@/views/pages/orders/index.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'add',
            name: 'orders-order-add',
            component: () => import('@/views/pages/orders/AddOrder.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'list',
            component: {
              render(c) {
                return c('router-view');
              },
            },

            children: [
              {
                path: '',
                name: 'orders-list',
                component: () => import('@/views/pages/orders/list.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: ':id',
            component: {
              render(c) {
                return c('router-view');
              },
            },

            children: [
              {
                path: '',
                name: 'orders-details',
                component: () =>
                  import('@/views/pages/orders/orderDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: 'offers',
                name: 'offers-for-order',
                component: () =>
                  import('@/views/pages/orders/offersForOrder.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'offers',
        component: {
          render(c) {
            return c('router-view');
          },
        },

        children: [
          {
            path: '',
            name: 'offers',
            component: () => import('@/views/pages/offers/index.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: ':id',
            name: 'offers-details',
            component: () => import('@/views/pages/offers/offerDetails.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'project',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: ':id',
                name: 'offers-project',
                component: () =>
                  import('@/views/pages/offers/offersForProjet.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'projects',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'projects',
            component: () => import('@/views/pages/projects/index.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'project-closing-requests',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'project-closing-requests',
                component: () =>
                  import('@/views/pages/projects/projectClosingRequests.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: 'list',
                name: 'project-closing-requests-list',
                component: () =>
                  import(
                    '@/views/pages/projects/projectClosingRequestsList.vue'
                  ),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: 'project-cancel-requests',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'project-cancel-requests',
                component: () =>
                  import('@/views/pages/projects/projectCancelRequests.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: ':id',
            component: {
              render(c) {
                return c('router-view');
              },
            },

            children: [
              {
                path: '',
                name: 'project-details',
                component: () => import('@/views/pages/projects/details.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: 'offers',
                name: 'offers-for-project',
                component: () =>
                  import('@/views/pages/projects/offersForProject.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'financial',
        component: {
          render(c) {
            return c('router-view');
          },
        },

        children: [
          {
            path: '',
            name: 'financial',
            component: () => import('@/views/pages/financial/index.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
        ],
      },
      {
        path: 'reviews',
        component: {
          render(c) {
            return c('router-view');
          },
        },

        children: [
          {
            path: '',
            name: 'reviews',
            component: () => import('@/views/pages/reviews/index.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'projects',
            name: 'reviews-projects',
            component: () => import('@/views/pages/reviews/projects.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'employees',
            name: 'reviews-employees',
            component: () => import('@/views/pages/reviews/employees.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
        ],
      },
    ],
  },
];
