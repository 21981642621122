import router from '@/router';

export default {
  async loadProfileInfo({ commit }, data) {
    commit('loadingStart', null);
    let url = '';
    const loginData = this._vm.$cookies.get('super-login-data', {
      parseJSON: true,
    });
    if (loginData.account_type === 'super') {
      url = 'super/user/me';
    }
    return await this._vm.$http
      .get(url)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setProfileInfo', respo.data.data);
          commit('loadingFinish');
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async updateProfile({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post('super/user/profile/update', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'update_profile_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async changePassword({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post('auth/password/change', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'change_password_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
