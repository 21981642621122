export default {
  async loadAllCompanies({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/business?page=${data.page}&per_page=${data.per_page}&status=${data.status}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setCompanies', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadAllCompaniesForSelect({ commit }) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/all-business`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAllCompaniesForSelect', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadCompaniesDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/business/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setCompanyDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async changeCompaniesStatus({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/business/${data.id}/status`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setCompanyDetails', respo.data.data.content);
          commit('successMessage', 'change_status_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
