export default {
  setOrders: (state, data) => {
    state.allOrders = data.content;
    state.totalOrders = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setOrderDetails: (state, data) => {
    state.orderDetails = data;
  },
};
