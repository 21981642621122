export default {
  async loadFinancial({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/financial?page=${data.page}&per_page=${data.per_page}&type=${data.type}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setFinancial', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadFinancialDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/financial/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setFinancialDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async addFinancial({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/financial', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'financy_saved_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
