import i18n from '@/libs/i18n';
import middleware from '../../middleware';
import middlewareAccountState from '../../middlewareAccountState';

export default [
  {
    path: 'login',
    name: 'login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'auth-icon-icon',
    },
  },
  {
    path: 'forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'auth-icon-icon',
    },
  },
  {
    path: 'reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'auth-icon-icon',
    },
  },
  {
    path: 'validate-email',
    name: 'validate-email',
    component: () => import('@/views/pages/authentication/ValidateEmail.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'auth-icon-icon',
    },
  },
  {
    path: 'verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/authentication/VerifyEmail.vue'),
    meta: {
      middleware,
      layout: 'auth',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      authImage: 'auth-icon-icon',
      noBackButton: true,
    },
  },
  {
    path: ':role',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      middleware: middlewareAccountState,
    },
    children: [
      {
        path: 'settings',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'settings',
            component: () => import('@/views/pages/settings/settings.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'administrators',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'administrators',
                component: () =>
                  import('@/views/pages/settings/administrators.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: 'add',
                name: 'add-administrators',
                component: () => import('@/views/pages/settings/addAdmin.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: ':id',
                name: 'administrator-details',
                component: () =>
                  import('@/views/pages/settings/administratorDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: 'account-information',
            name: 'account-information',
            component: () =>
              import('@/views/pages/settings/account-details.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'operation-log',
            name: 'operation-log',
            component: () => import('@/views/pages/settings/operationLog.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'advanced',
            name: 'advanced',
            component: () => import('@/views/pages/settings/advanced.vue'),
            meta: {
              middleware: [middleware, middlewareAccountState],
            },
          },
          {
            path: 'sectors',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'sectors',
                component: () => import('@/views/pages/settings/sectors.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: ':id',
                name: 'sector-details',
                component: () =>
                  import('@/views/pages/settings/sectorDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: 'fields',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'fields',
                component: () => import('@/views/pages/settings/fields.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: ':id',
                name: 'field-details',
                component: () =>
                  import('@/views/pages/settings/fieldDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
          {
            path: 'services',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'services',
                component: () => import('@/views/pages/settings/services.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
              {
                path: ':id',
                name: 'service-details',
                component: () =>
                  import('@/views/pages/settings/serviceDetails.vue'),
                meta: {
                  middleware: [middleware, middlewareAccountState],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
