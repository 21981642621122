// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async submitLogin({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('auth/login', data)
      .then(respo => {
        if (respo.status === 200) {
          const date = new Date();
          const responseData = {
            ...respo.data.data.content,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.content.expires_in,
            ),
          };

          this._vm.$http.defaults.headers.common.Authorization = `Bearer ${responseData.token}`;
          this._vm.$cookies.set(
            'super-login-data',
            JSON.stringify(responseData),
            null,
            null,
            // 'localhost',
            // 'super-talentsin.wameedprojects.com',
            'super.talentsin.sa',
            true,

            // httpOnly:true,
            // sameSite: true
          );

          const loginData = this._vm.$cookies.get('super-login-data', {
            parseJSON: true,
          });

          if (loginData) {
            router.push({
              name: loginData.permissions[0].split('view_')[1],
              params: {
                lang: i18n.locale,
                role: loginData.account_type,
              },
            });
          }
          setTimeout(() => {
            window.location.reload();
        }, 200);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async refreshToken({ commit }, data) {
    commit('loadingStart', null);

    await this._vm.$http
      .post('auth/refresh', null, { headers: { refresh_token: data } })
      .then(respo => {
        if (respo.status === 200) {
          delete this._vm.$http.defaults.headers.common.Authorization;
          const date = new Date();
          const responseData = {
            ...respo.data.data,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.expires_in,
            ),
          };
          this._vm.$http.defaults.headers.common.Authorization = `Bearer ${responseData.token}`;
          // this._vm.$cookies.remove('super-login-data')
          // this._vm.$cookies.remove('super-login-data', '/' + i18n.locale, 'localhost',true )
          this._vm.$cookies.set(
            'super-login-data',
            JSON.stringify(responseData),
            // null,
            // "/" + i18n.locale,
            // "localhost",
            // true

            // httpOnly:true,
            // sameSite: true
          );
          // let loginData = this._vm.$cookies.get('super-login-data', { parseJSON: true })
          // if (loginData) {
          // dispatch('loadUserDetail')
          // commit('setLoggedInuser', loginData)
          // router.push({ path: '/' + i18n.locale + '/dashboard' })

          // }

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async checkLoggedIn({ commit, dispatch }) {
    // let loginData = JSON.parse(localStorage.getItem('loginData'))\
    const loginData = this.$cookies.get('super-login-data', { parseJSON: true });
    if (loginData) {
      if (this._vm.$http.defaults.headers.common.Authorization === undefined) {
        this._vm.$http.defaults.headers.common.Authorization = `Bearer ${loginData.token}`;
      }
      commit('setLoggedInuser', loginData);

      dispatch('loadUserDetail');
    }
  },
  async logout({ commit }) {
    this._vm.$cookies.remove('super-login-data');
    this._vm.$cookies.remove(
      'super-login-data',
      null,
      // 'localhost',
      // 'super-talentsin.wameedprojects.com',
      'super.talentsin.sa',
      true,

      // httpOnly:true,
      // sameSite: true
    );

    const loginData = this._vm.$cookies.get('super-login-data', {
      parseJSON: true,
    });
    if (!loginData) {
      this._vm.$http.defaults.headers.common.Authorization = undefined;

      // commit("setLoggedOutuser");
      // router.go();
      window.location.reload();
    }
  },
  async forgetPassword({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('auth/password/forgot', data)
      .then(respo => {
        if (respo.status === 200) {
          commit('setEmailTokenId', respo.data.data.token_id);
          this._vm.$cookies.set(
            'forgot-password-email',
            JSON.stringify(data),
            60 * 10,
            // null,
            // "localhost",
            // true

            // httpOnly:true,
            // sameSite: true
          );
          router.push({
            name: 'validate-email',
            params: { lang: i18n.locale },
          });

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async emailVerification({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post('auth/email/verified', data)
      .then(respo => {
        if (respo.status === 200) {
          commit('setEmailTokenId', respo.data.data.token_id);
          commit('setOtpVerification', null);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },

  async confirmEmailVerification({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post('auth/email/verified/check', data)
      .then(respo => {
        if (respo.status === 200) {
          return true;
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          if (error.response.data.code === '01_06') {
            commit('setOtpVerification', 2);
          }
        }
        throw error;
      });
  },

  async resendEmailVerify({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('auth/email/verified', data)
      .then(respo => {
        if (respo.status === 200) {
          this._vm.$cookies.set(
            'forgot-password-email',
            JSON.stringify(userEmail),
            60 * 10,
            // null,
            // "localhost",
            // true

            // httpOnly:true,
            // sameSite: true
          );

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async confirmCode({ commit }, data) {
    commit('loadingStart', null);

    const userEmail = this._vm.$cookies.get('forgot-password-email', {
      parseJSON: true,
    });
    if (userEmail) {
      await this._vm.$http
        .post('auth/email/verified/check', { ...data, ...userEmail })
        .then(respo => {
          if (respo.status === 200) {
            commit('successMessage', 'code_confirmed_successfully');

            router.push({
              name: 'reset-password',
              params: {
                token: respo.data.data.token,
                lang: i18n.locale,
              },
            });

            commit('loadingFinish');
          }
        })
        .catch(error => {
          commit('loadingFinish');
          throw error;
        });
    } else {
      commit('loadingFinish');

      router.push({
        name: 'forgot-password',
        params: { lang: i18n.locale },
      });
    }
  },
  async resetPassword({ commit }, data) {
    commit('loadingStart', null);

    const userEmail = this._vm.$cookies.get('forgot-password-email', {
      parseJSON: true,
    });
    if (userEmail) {
      await this._vm.$http
        .post('auth/password/reset', { ...data, ...userEmail })
        .then(respo => {
          if (respo.status === 200) {
            commit('successMessage', 'change_password_successfully');
            router.push({
              name: 'login',
              params: { lang: i18n.locale },
            });
            this._vm.$cookies.remove(
              'forgot-password-email',
              null,
              // 'localhost',
              // 'super-talentsin.wameedprojects.com',
              'super.talentsin.sa',
              true,

              // httpOnly:true,
              // sameSite: true
            );
            commit('loadingFinish');
          }
        })
        .catch(error => {
          commit('loadingFinish');
          throw error;
        });
    } else {
      commit('loadingFinish');

      router.push({
        name: 'forgot-password',
        params: { lang: i18n.locale },
      });
    }
  },

  async verifyAccount({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('verify-account', data)
      .then(respo => {
        if (respo.status === 200) {
          commit('successMessage', 'code_confirmed_successfully');
          const loginData = this._vm.$cookies.get('super-login-data', {
            parseJSON: true,
          });
          if (loginData) {
            this._vm.$cookies.set(
              'super-login-data',
              JSON.stringify({
                ...loginData,
                complete_status: '',
              }),
              // null,
              // null,
              // "localhost",
              // true

              // httpOnly:true,
              // sameSite: true
            );
            router.push({
              name: loginData.permissions[0].split('view_')[1],

              params: { lang: i18n.locale },
            });
          }

          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async resendCode({ commit }) {
    commit('loadingStart', null);
    const forgotPasswordEmail = this._vm.$cookies.get('forgot-password-email', {
      parseJSON: true,
    });
    if (forgotPasswordEmail) {
      await this._vm.$http
        .post('auth/password/forgot', forgotPasswordEmail)
        .then(respo => {
          if (respo.status === 200) {
            this._vm.$cookies.set(
              'forgot-password-email',
              JSON.stringify(forgotPasswordEmail),
              60 * 10,
              // null,
              // "localhost",
              // true

              // httpOnly:true,
              // sameSite: true
            );
            commit('loadingFinish');
          }
        })
        .catch(error => {
          commit('loadingFinish');
          throw error;
        });
    }
  },
};
