export default {
  getAllFinancial(state) {
    return state.allFinancial;
  },
  getTotalFinancial(state) {
    return state.totalFinancial;
  },
  getFinancialDetails(state) {
    return state.financialDetails;
  },
};
