// import axios from '@/plugins/axios';
import i18n from '@/libs/i18n';
import router from '@/router';

export default {
  async uploadFiles({ commit }, data) {
    commit('loadingStart', null);
    return await this._vm.$http
      .post('file/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit('loadingFinish');
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadFile({ commit }, file) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`file/download/${file}`, {
        responseType: 'blob',
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit('loadingFinish');
          const blob = respo.data;

          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadIndustryPreference({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('industry-preference')
      .then((respo) => {
        if (respo.status === 200) {
          commit('setIndustryPreference', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadRolePreference({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('role-preference')
      .then((respo) => {
        if (respo.status === 200) {
          commit('setRolePreference', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadAllServices({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('services')
      .then((respo) => {
        if (respo.status === 200) {
          commit('setServices', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadDashboardDetails({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('super/dashboard/details')
      .then((respo) => {
        if (respo.status === 200) {
          commit('setDashboardDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadAdvancedSettings({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('super/advanced-settings')
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAdvancedSettings', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async updateAdvancedSettings({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/advanced-settings', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setAdvancedSettings', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async downloadInvoice({ commit }, invoice) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`invoice/view/${invoice}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('loadingFinish');
          window.open(respo.data, '_blank');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadCountries({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get('countries')
      .then((respo) => {
        if (respo.status === 200) {
          commit('setCountries', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async loadCities({ commit }, countruId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`cities/${countruId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setCities', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
