import Vue from 'vue';
import arrowDown from './arrow-down.svg';
import arrowPrev from './arrow-prev.svg';
import arrowNext from './arrow-next.svg';
import bill from './bill.svg';
import chat from './chat.svg';
import check from './check.svg';
import close from './close.svg';
import emails from './emails.svg';
import applicants from './applicants.svg';
import eyeoff from './eyeoff.svg';
import eyeon from './eyeon.svg';
import logout from './logout.svg';
import moon from './moon.svg';
import nodata from './nodata.svg';
import search from './search.svg';
import trainging from './trainging.svg';
import logoDark from './logo-dark.svg';
import logoDark2 from './logo-dark-2.svg';
import logoLarg from './logo_larg.svg';
import users from './users.svg';
import skills from './skills.svg';
import bag from './bag.svg';
import jobsBag from './jobs-bag.svg';
import location from './location.svg';
import clock from './clock.svg';
import file from './file.svg';
import download from './download.svg';
import company from './company.svg';
import government from './government.svg';
import individual from './individual.svg';
import organization from './organization.svg';

// IMPORTS - START
import tick from './tick.svg';
import trash from './trash.svg';
import falseCheck from './false-check.svg';
import trueCheck from './true-check.svg';
import filter from './filter.svg';
import logout2 from './logout2.svg';
import orders from './orders.svg';
import orderPage from './orders-page.svg';
import offerPage from './offers-page.svg';
import usersPage from './users-page.svg';
import companiesPage from './companies-page.svg';
import newAccountsPage from './new-accounts-page.svg';
import projects from './projects.svg';
import money from './money.svg';
import statusUp from './status-up.svg';
import reviews from './reviews.svg';
import reviewsProjects from './reviewsProjects.svg';
import reviewsEmployees from './reviewsEmployees.svg';
import messages from './messages.svg';
import home from './home.svg';
import setting from './setting.svg';
import ticketDiscount from './ticket-discount.svg';
import star from './star.svg';
import menu from './menu.svg';
import panel1 from './panel1.svg';
import panel2 from './panel2.svg';
import panel3 from './panel3.svg';
import panel4 from './panel4.svg';
import starFill from './star-fill.svg';
import locationFill from './location-fill.svg';
import addCircle from './add-circle.svg';
import addFilldCircle from './add-filled-circle.svg';
import verticalDots from './vertical-dots.svg';
import sortable from './sortable.svg';
import packages from './packages.svg';
import logo from './logo.svg';
import lang from './lang.svg';
import email from './email.svg';
import lock from './lock.svg';
import arrowNextCircle from './arrow-next-circle.svg';
import cirlcePrev from './circle-prev.svg';
import stop from './stop.svg';
import unacceptable from './unacceptable.svg';
import chat2 from './chat2.svg';
import notification from './notification.svg';
import loginSvg1 from './login-svg-1.svg';
import loginSvg2 from './login-svg-2.svg';
import loginSvg3 from './login-svg-3.svg';
import imgClose from './img-close.svg';
import imgUpload from './img-upload.svg';
import fileUpload from './file-upload.svg';
import notification2 from './notification-icon.svg';
import warning from './warning.svg';
import bill2 from './bill2.svg';
import timer from './timer.svg';
import openFile from './open-file.vue';
import openFileWithoutText from './open-file-without-text.vue';
import applyOffer from './apply_offer.svg';

import dashboardProfit from './dashboardProfit.svg';
import dashboardOrders from './dashboardOrders.svg';
import dashboardOffers from './dashboardOffers.svg';
import dashboardProjects from './dashboardProjects.svg';

import dashboardCompoany from './dashboardCompoany.svg';
import dashboardEmployee from './dashboardEmployee.svg';
import dashboardJob from './dashboardJob.svg';
import canceledIcon from './canceled-icon.svg';
import infoIcon from './info-icon.svg';
import closeSideModal from './close_side_modal.svg';
//  IMPORTS - END

import offersCompleted from './offers_completed.svg';
import offersNow from './offers_now.svg';
import update from './update.svg';

import personalInformation from './personal-information.svg';
import admins from './admins.svg';
import sectors from './sectors.svg';
import fields from './fields.svg';
import services from './services.svg';
import closeSelect from './close_select.svg';
import employeeAcccount from './employee_acccount.svg';
import usersNav from './users-nav.svg';
import confirmation from './confirmation.svg';
import stopping from './stopping.svg';
import stoppingCheck from './stopping-check.svg';
import rejected from './rejectedIcon.svg';
import financialProcess from './financialProcessIcon.svg';
import sharing from './sharing.svg';
import refusal from './refusal.svg';
import defaultUserImage from './default_user_image.svg';
import subtypeEmployee from './subtype-employee.svg';
import subtypeBusiness from './subtype-business.svg';
import typeMaturityFromCompany from './typeMaturityFromCompany.svg';
import administrativeFees from './administrativeFees.svg';
import typePayEmployee from './typePayEmployee.svg';
import offersNav from './offers.svg';
import checkBoxOffer from './check-box-offer.svg';
import checkPayment from './check-payment.svg';
import operationLog from './operation_log.svg';
import settings from './settings.svg';
import activation from './activation.svg';

import projectCancelRequests from './project_cancel_requests.svg';
import projectClosingRequests from './project_closing_requests.svg';
import stoppedModal from './stoppedModal.svg';
import sendModal from './sendModal.svg';
import reviewStars2 from './review_stars2.svg';
//  ICON COMPONENTS - START
import ar from './ar.svg';
import en from './en.svg';

import pdf from './pdf.svg';
import word from './word.svg';
import notAuthorized from "./not-authorized.svg";
import notFound from "./not-found.svg";
import authIcon from "./auth-icon.svg";

Vue.component('timer-icon', timer);
Vue.component('bill2-icon', bill2);
Vue.component('chat2-icon', chat2);
Vue.component('tick-icon', tick);
Vue.component('add-filled-circle-icon', addFilldCircle);
Vue.component('trash-icon', trash);
Vue.component('true-check-icon', trueCheck);
Vue.component('false-check-icon', falseCheck);
Vue.component('add-circle-icon', addCircle);
Vue.component('location-fill-icon', locationFill);
Vue.component('star-fill-icon', starFill);
Vue.component('panel1-icon', panel1);
Vue.component('panel2-icon', panel2);
Vue.component('panel3-icon', panel3);
Vue.component('panel4-icon', panel4);
Vue.component('orders-icon', orders);
Vue.component('orders-page-icon', orderPage);
Vue.component('offers-page-icon', offerPage);
Vue.component('users-page-icon', usersPage);
Vue.component('companies-page-icon', companiesPage);
Vue.component('new-accounts-page-icon', newAccountsPage);
Vue.component('projects-icon', projects);
Vue.component('money-icon', money);
Vue.component('star-icon', star);
Vue.component('ticket-discount-icon', ticketDiscount);
Vue.component('status-up-icon', statusUp);
Vue.component('reviews-icon', reviews);
Vue.component('reviews-projects-icon', reviewsProjects);
Vue.component('reviews-employees-icon', reviewsEmployees);
Vue.component('messages-icon', messages);
Vue.component('home-icon', home);
Vue.component('setting-icon', setting);
Vue.component('menu-icon', menu);
Vue.component('logout2-icon', logout2);
Vue.component('filter-icon', filter);
Vue.component('vertical-dots-icon', verticalDots);
Vue.component('sortable-icon', sortable);
Vue.component('packages-icon', packages);
Vue.component('logo-light', logo);
Vue.component('lang-icon', lang);
Vue.component('email-icon', email);
Vue.component('lock-icon', lock);
Vue.component('arrow-next-circle-icon', arrowNextCircle);
Vue.component('circle-prev-icon', cirlcePrev);
Vue.component('stop-icon', stop);
Vue.component('notification-icon', notification);
//  ICON COMPONENTS - END

Vue.component('arrow-down-icon', arrowDown);
Vue.component('bill-icon', bill);
Vue.component('chat-icon', chat);
Vue.component('check-icon', check);
Vue.component('close-icon', close);
Vue.component('emails-icon', emails);
Vue.component('applicants-icon', applicants);
Vue.component('eyeoff-icon', eyeoff);
Vue.component('eyeon-icon', eyeon);
Vue.component('logout-icon', logout);
Vue.component('moon-icon', moon);
Vue.component('nodata-icon', nodata);
Vue.component('search-icon', search);
Vue.component('trainging-icon', trainging);
Vue.component('logo-dark', logoDark);
Vue.component('logo-dark-2', logoDark2);
Vue.component('logo-larg', logoLarg);
Vue.component('users-icon', users);
Vue.component('skills-icon', skills);
Vue.component('bag-icon', bag);
Vue.component('jobs-bag-icon', jobsBag);
Vue.component('location-icon', location);
Vue.component('clock-icon', clock);
Vue.component('file-icon', file);
Vue.component('download-icon', download);
Vue.component('arrow-prev-icon', arrowPrev);
Vue.component('arrow-next-icon', arrowNext);
Vue.component('company-icon', company);
Vue.component('government-icon', government);
Vue.component('individual-icon', individual);
Vue.component('organization-icon', organization);
Vue.component('login-svg1', loginSvg1);
Vue.component('login-svg2', loginSvg2);
Vue.component('login-svg3', loginSvg3);
Vue.component('img-close-icon', imgClose);
Vue.component('img-upload-icon', imgUpload);
Vue.component('file-upload-icon', fileUpload);
Vue.component('notification2-icon', notification2);
Vue.component('warning-icon', warning);
Vue.component('unacceptable-icon', unacceptable);
Vue.component('open-file-icon', openFile);
Vue.component('open-file-without-text-icon', openFileWithoutText);
Vue.component('apply-offer-icon', applyOffer);
Vue.component('canceled-icon', canceledIcon);
Vue.component('info-icon', infoIcon);
Vue.component('dashboard-profit-icon', dashboardProfit);
Vue.component('dashboard-orders-icon', dashboardOrders);
Vue.component('dashboard-offers-icon', dashboardOffers);
Vue.component('dashboard-projects-icon', dashboardProjects);
Vue.component('dashboard-offers-completed-icon', offersCompleted);
Vue.component('dashboard-offers-now-icon', offersNow);
Vue.component('dashboard-compoany-icon', dashboardCompoany);
Vue.component('dashboard-employee-icon', dashboardEmployee);
Vue.component('dashboard-job-icon', dashboardJob);
Vue.component('close-side-modal-icon', closeSideModal);
Vue.component('update-icon', update);
Vue.component('personal-information-icon', personalInformation);
Vue.component('admins-icon', admins);
Vue.component('sectors-icon', sectors);
Vue.component('fields-icon', fields);
Vue.component('services-icon', services);
Vue.component('close-select-icon', closeSelect);
Vue.component('employee-acccount-icon', employeeAcccount);
Vue.component('users-nav-icon', usersNav);
Vue.component('confirmation-icon', confirmation);
Vue.component('stopping-icon', stopping);
Vue.component('stopping-check-icon', stoppingCheck);
Vue.component('rejected-icon', rejected);
Vue.component('financial-process-icon', financialProcess);
Vue.component('sharing-icon', sharing);
Vue.component('refusal-icon', refusal);
Vue.component('default-user-image-icon', defaultUserImage);
Vue.component('subtype-employee-icon', subtypeEmployee);
Vue.component('subtype-business-icon', subtypeBusiness);
Vue.component('administrative-fees-icon', administrativeFees);
Vue.component('type-maturity-from-company-icon', typeMaturityFromCompany);
Vue.component('type-pay-employee-icon', typePayEmployee);
Vue.component('offers-icon', offersNav);
Vue.component('check-box-offer-icon', checkBoxOffer);
Vue.component('check-payment-icon', checkPayment);
Vue.component('operation-log-icon', operationLog);
Vue.component('settings-icon', settings);
Vue.component('activation-icon', activation);
Vue.component('projec-cancel-requests-icon', projectCancelRequests);
Vue.component('project-closing-requests-icon', projectClosingRequests);
Vue.component('stopped-modal-icon', stoppedModal);
Vue.component('send-icon', sendModal);
Vue.component('review-stars-2-icon', reviewStars2);
Vue.component('ar-icon', ar);
Vue.component('en-icon', en);
Vue.component('pdf-icon', pdf);
Vue.component('word-icon', word);
Vue.component("not-found-icon", notFound);
Vue.component("not-authorized-icon", notAuthorized);
Vue.component("auth-icon-icon", authIcon);
