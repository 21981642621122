export default {
    setOffers: (state, data) => {
      state.allOffers = data.content;
      state.totalOffers = {
        totalItems: data.total,
        totalPages: data.last_page,
      };
    },
    setOfferDetails: (state, data) => {
      state.offerDetails = data;
    },
  };
