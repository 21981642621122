import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import { localize } from 'vee-validate';
import i18n from '@/libs/i18n';

import store from '@/store';
import pages from './routes/pages/index';

const loginData = Vue.$cookies.get('super-login-data', { parseJSON: true });

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}/${
        loginData ? loginData.permissions[0].split('view_')[1] : 'login'
      }`,
    },
    {
      path: '/:lang',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        ...pages,
        
      ],
    },
    {
      path: '*',
      redirect: `/${i18n.locale}/not-found`,
    },
  ],
});

// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const langs = ['ar', 'en'];
  const loginData = Vue.$cookies.get('super-login-data', { parseJSON: true });
  if (langs.includes(to.params.lang)) {
    i18n.locale = to.params.lang;
    localize(to.params.lang);
    axios.defaults.headers.common.locale = to.params.lang;
    store.commit('TOGGLE_RTL', to.params.lang);
    const roles = ['super'];

    if (to.meta.middleware) {
      if (to.params.role && !roles.includes(to.params.role)) {
        if (loginData) {
          return next({
            name: to.params.role,
            params: { lang: i18n.locale, role: loginData.account_type },
          });
        }
        return next({ name: 'login', params: { lang: 'ar' } });
      }
      if (to.params.role && roles.includes(to.params.role) && !to.name) {
        return next({
          name: loginData.permissions[0].split('view_')[1],
          params: { lang: i18n.locale, role: loginData.account_type },
        });
      }
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);
      return middleware[0]({ ...context, next: nextMiddleware });
    }
    if (loginData) {
      if (to.params.role && !roles.includes(to.params.role)) {
        return next({
          name: to.params.role,
          params: { lang: i18n.locale, role: loginData.account_type },
        });
      }
      if (to.params.role && roles.includes(to.params.role) && !to.name) {
        return next({
          name: loginData.permissions[0].split('view_')[1],
          params: { lang: i18n.locale, role: loginData.account_type },
        });
      }
      return next();
    }
    return next({ name: 'login', params: { lang: 'ar' } });
  }
  if (to.name) {
    if (loginData) {
      next({
        name: to.name,
        params: { lang: i18n.locale, role: loginData.account_type },
      });
    } else {
      return next({ name: 'login', params: { lang: 'ar' } });
    }
  } else {
    const roles = ['super'];
    if (loginData) {
      if (to.params.role && !roles.includes(to.params.role)) {
        next({
          name: to.params.role,
          params: { lang: i18n.locale, role: loginData.account_type },
        });
      } else {
        next({
          name: loginData.permissions[0].split('view_')[1],
          params: { lang: i18n.locale, role: loginData.account_type },
        });
      }
    } else {
      return next({ name: 'login', params: { lang: 'ar' } });
    }
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
