export default {
  allEmployees: null,
  allNewAccounts: null,
  employeeDetails: null,
  totalEmployees: {
    totalItems: null,
    totalPages: null,
  },
  totalNewAccounts: {
    totalItems: null,
    totalPages: null,
  },
};
