import Vue from 'vue';
import store from '@/store/index';
import router from '@/router';
import i18n from '@/libs/i18n';
import { setTimeout } from 'core-js';
import { async } from 'vue-phone-number-input/dist/vue-phone-number-input.common';

export default async function middleware({ to, next }) {
  const loginRoutes = [
    'login',
    'signup',
    'forgot-password',
    'reset-password',
    'validate-email',
    'verify-email',
  ];
  const loginData = Vue.$cookies.get('super-login-data', { parseJSON: true });
  if (loginData) {
    // hasAccess(to.name)
    await hasAccess(to.fullPath.split('/')[3].toLowerCase()).then(r => {
      if (!r) {
        return next({
          name: 'page-not-authorized',
          params: { lang: i18n.locale, role: loginData.account_type },
        });
      }
    });

    if (to.params.role && loginData.account_type === to.params.role) {
      if (loginData.remember_me) {
        const date = Date.now();
        if (loginData.exp_date <= date) {
          store.dispatch('refreshToken', loginData.refresh_token).then(() => {
            if (loginRoutes.includes(to.name)) {
              return next({
                name: loginData.permissions[0].split('view_')[1],
                params: { lang: 'ar', role: loginData.account_type },
              });
            }
            return next();
          });
        } else {
          if (loginRoutes.includes(to.name)) {
            return next({
              name: loginData.permissions[0].split('view_')[1],
              params: { lang: 'ar', role: loginData.account_type },
            });
          }
          return next();
        }
      } else {
        if (loginRoutes.includes(to.name)) {
          return next({
            name: loginData.permissions[0].split('view_')[1],
            params: { lang: 'ar', role: loginData.account_type },
          });
        }
        return next();
      }
    }
    if (to.name) {
      return next({
        name: to.name,
        params: { lang: 'ar', role: loginData.account_type },
      });
    }
    return next({
      name: loginData.permissions[0].split('view_')[1],
      params: { lang: 'ar', role: loginData.account_type },
    });
  }
  if (!loginRoutes.includes(to.name)) {
    return next({ name: 'login', params: { lang: 'ar' } });
  }
  return next();
}

async function hasAccess(name) {
  let permissions = [];
  if (store.getters.getProfileInfo == null) {
    await store.dispatch('loadProfileInfo');
  }

  permissions = store.getters.getProfileInfo.permissions;
  switch (name) {
    case 'dashboard':
      return permissions.includes('view_dashboard');

    case 'users':
      return permissions.includes('view_users');

    case 'orders':
      return permissions.includes('view_orders');

    case 'offers':
      return permissions.includes('view_offers');

    case 'projects':
      return permissions.includes('view_projects');

    case 'financial':
      return permissions.includes('view_financial');

    case 'reviews':
      return permissions.includes('view_reviews');

    case 'settings':
      return permissions.includes('view_settings');

    default:
      return false;
  }
}
