export default {
  getIndustryPreference(state) {
    return state.industryPreference;
  },
  getRolePreference(state) {
    return state.rolePreference;
  },
  getServices(state) {
    return state.services;
  },
  getDashboardDetails(state) {
    return state.dashboardDetails;
  },
  getAdvancedSettings(state) {
    return state.advancedSettings;
  },
  getCountries(state) {
    return state.countries;
  },
  getCities(state) {
    return state.cities;
  },
};
