export default {
  getAllCompanies(state) {
    return state.allCompanies;
  },
  getTotalCompanies(state) {
    return state.totalCompanies;
  },
  getCompanyDetails(state) {
    return state.companyDetails;
  },
  getAllCompaniesForSelect(state) {
    return state.allCompaniesForSelect;
  },
};
