export default {
  setCompanies: (state, data) => {
    state.allCompanies = data.content;
    state.totalCompanies = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setCompanyDetails: (state, data) => {
    state.companyDetails = data;
  },
  setAllCompaniesForSelect: (state, data) => {
    state.allCompaniesForSelect = data;
  },
};
