import Vue from 'vue';
import VueCookies from 'vue-cookies';
import superAdmin from './superAdmin';
import auth from './auth';

Vue.use(VueCookies);
const loginData = Vue.$cookies.get('super-login-data', { parseJSON: true });
let routesList = [];
if (loginData) {
  if (loginData.account_type === 'super') {
    routesList = superAdmin;
  }
}

export default [...auth, ...routesList];
