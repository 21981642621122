export default {
  setProjects: (state, data) => {
    state.allProjects = data.content;
    state.totalProjects = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setPrijectDetails: (state, data) => {
    state.prijectDetails = data;
  },
};
