export default {
  async loadOrders({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/projects?page=${data.page}&per_page=${data.per_page}&status=${data.status}&industry_preference=${data.industry_preference}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOrders', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadOrdersDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/projects/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOrderDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async changeOrdersStatus({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/projects/${data.id}/status`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOrderDetails', respo.data.data.content);
          commit('successMessage', 'change_status_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async addOrder({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/projects', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'order_saved_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
