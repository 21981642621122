export default {
  async loadAllEmployees({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/employees?page=${data.page}&per_page=${data.per_page}&status=${data.status}&order_by=${data.order_by}&search=${data.search}`,
      )
      .then(respo => {
        if (respo.status === 200) {
          commit('setEmployees', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadNewAccounts({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/new-accounts?page=${data.page}&per_page=${data.per_page}&status=${data.status}&industry_preference=${data.industry_preference}&order_by=${data.order_by}&search=${data.search}`,
      )
      .then(respo => {
        if (respo.status === 200) {
          commit('setNewAccounts', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadEmployeesDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/employees/${applicantsId}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setEmployeeDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async changeEmployeesStatus({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/employees/${data.id}/status`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('setEmployeeDetails', respo.data.data.content);
          commit('successMessage', 'change_status_successfully');
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
};
