import Vue from 'vue';
import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  BootstrapVueIcons,
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import i18n from '@/libs/i18n';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { abilitiesPlugin } from '@casl/vue';
import router from './router';
import store from './store';
import App from './App.vue';
import './assets/svg/index';
import './assets/images/pages/index';
// import ability from './services/ability';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/portal-vue';
import '@/libs/vue-cookies';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/quill-editor';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/vue-debounce';
import '@/libs/moment';
import '@/libs/tour';

import Permissions from './mixins/Permissions.vue';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
// name is optional
Vue.use(VueLodash, { name: 'custom', lodash });

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.mixin(Permissions);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
// require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// require('@core/assets/custom.scss') // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(abilitiesPlugin, {
  useGlobalProperties: true,
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
