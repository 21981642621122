export default {
  getAllProjects(state) {
    return state.allProjects;
  },
  getTotalProjects(state) {
    return state.totalProjects;
  },
  getPrijectDetails(state) {
    return state.prijectDetails;
  },
};
