export default {
  getAllEmployees(state) {
    return state.allEmployees;
  },
  getTotalEmployees(state) {
    return state.totalEmployees;
  },
  getAllNewAccounts(state) {
    return state.allNewAccounts;
  },
  getTotalNewAccounts(state) {
    return state.totalNewAccounts;
  },
  getEmployeeDetails(state) {
    return state.employeeDetails;
  },
};
