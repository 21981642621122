export default {
  async loadOperations({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/operation-log?page=${data.page}&per_page=${data.per_page}&user_id=${data.user_id}&industry_preference=${data.industry_preference}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOperations', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
