export default {
  async loadReviews({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/reviews?page=${data.page}&per_page=${data.per_page}&type=${data.type}&industry_preference=${data.industry_preference}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setReviews', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
