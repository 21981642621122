export default {
    getAllOffers(state) {
      return state.allOffers;
    },
    getTotalOffers(state) {
      return state.totalOffers;
    },
    getOfferDetails(state) {
      return state.offerDetails;
    },
  };
