export default {
  getAllSectors(state) {
    return state.allSectors;
  },
  getTotalSectors(state) {
    return state.totalSectors;
  },
  getAllFields(state) {
    return state.allFields;
  },
  getTotalFields(state) {
    return state.totalFields;
  },
  getSectorDetails(state) {
    return state.sectorDetails;
  },
};
