export default {
  setEmployees: (state, data) => {
    state.allEmployees = data.content;
    state.totalEmployees = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setNewAccounts: (state, data) => {
    state.allNewAccounts = data.content;
    state.totalNewAccounts = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setEmployeeDetails: (state, data) => {
    state.employeeDetails = data;
  },
};
