export default {
  async loadSectors({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/sectors-field?page=${data.page}&per_page=${data.per_page}&type=${data.type}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setSectors', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadFields({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/sectors-field?page=${data.page}&per_page=${data.per_page}&type=${data.type}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setFields', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadSectorsDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/sectors-field/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setSectorsDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async addSectors({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/sectors-field', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'saved_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async editSector({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/sectors-field/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'edit_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async deleteSector({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .delete(`super/sectors-field/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'delete_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
