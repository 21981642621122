import Vue from 'vue';
import store from '@/store/index';

export default function middlewareAccountState({ to, next }) {
  const loginData = Vue.$cookies.get('super-login-data', { parseJSON: true });
  const routeName = 'dashboard';
  if (loginData) {
    if (store.getters.getProfileInfo) {
      if (store.getters.getProfileInfo.status != 1 && to.name !== routeName) {
        return next({
          name: routeName,
          params: { lang: 'ar', role: loginData.account_type },
        });
      }
      return next();
    }
    return next();
  }
  return next({ name: 'login', params: { lang: 'ar' } });
}
