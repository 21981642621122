export default {
  getAllOrders(state) {
    return state.allOrders;
  },
  getTotalOrders(state) {
    return state.totalOrders;
  },
  getOrderDetails(state) {
    return state.orderDetails;
  },
};
