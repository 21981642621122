export default {
  setAdministrators: (state, data) => {
    state.allAdministrators = data.content;
    state.totalAdministrators = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setAdministratorDetails: (state, data) => {
    state.administratorDetails = data;
  },
};
