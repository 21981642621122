export default {
  setSectors: (state, data) => {
    state.allSectors = data.content;
    state.totalSectors = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setFields: (state, data) => {
    state.allFields = data.content;
    state.totalFields = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setSectorsDetails: (state, data) => {
    state.sectorDetails = data;
  },
};
