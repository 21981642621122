export default {
  getAllServices(state) {
    return state.allServices;
  },
  getTotalServices(state) {
    return state.totalServices;
  },
  getServiceDetails(state) {
    return state.serviceDetails;
  },
};
