export default {
  async loadServices({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/services?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&search=${data.search}`
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('setServices', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
  
  async loadServicesDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/services/${applicantsId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setServiceDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async addService({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .post('super/services', data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'saved_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async editService({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/services/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'edit_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },

  async deleteService({ commit }, id) {
    commit('loadingStart', null);
    await this._vm.$http
      .delete(`super/services/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit('successMessage', 'delete_successfully');
          commit('loadingFinish');
        }
      })
      .catch((error) => {
        commit('loadingFinish');
        throw error;
      });
  },
};
