import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import auth from './auth';
import profile from './profile';
import orders from './orders';
import projects from './projects';
import offers from './offers';
import business from './business';
import employees from './employees';
import financial from './financial';
import sectorsFields from './sectors-fields';
import services from './services';
import utilities from './utilities';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import administrators from './administrators';
import reviews from './reviews';
import operations from './operations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    administrators,
    reviews,
    operations,
    sectorsFields,
    services,
    auth,
    profile,
    orders,
    projects,
    offers,
    business,
    employees,
    financial,
    utilities,
  },
  strict: process.env.DEV,
});
