export default {
  setServices: (state, data) => {
    state.allServices = data.content;
    state.totalServices = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },
  setServiceDetails: (state, data) => {
    state.serviceDetails = data;
  },
};
