export default {
  getAllAdministrators(state) {
    return state.allAdministrators;
  },
  getTotalAdministrators(state) {
    return state.totalAdministrators;
  },
  getAdministratorDetails(state) {
    return state.administratorDetails;
  },
};
