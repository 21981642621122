export default {
  setIndustryPreference: (state, data) => {
    state.industryPreference = data;
  },
  setRolePreference: (state, data) => {
    state.rolePreference = data;
  },
  setServices: (state, data) => {
    state.services = data;
  },
  setDashboardDetails: (state, data) => {
    state.dashboardDetails = data;
  },
  setAdvancedSettings: (state, data) => {
    state.advancedSettings = data;
  },
  setCountries: (state, data) => {
    state.countries = data;
  },
  setCities: (state, data) => {
    state.cities = data;
  },
};
