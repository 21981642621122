export default {
  allSectors: null,
  allFields: null,
  sectorDetails: null,
  totalSectors: {
    totalItems: null,
    totalPages: null,
  },
  totalFields: {
    totalItems: null,
    totalPages: null,
  },
};
