export default {
  async loadProjects({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(
        `super/projects-agreed?page=${data.page}&per_page=${data.per_page}&status=${data.status}&industry_preference=${data.industry_preference}&order_by=${data.order_by}&search=${data.search}`,
      )
      .then(respo => {
        if (respo.status === 200) {
          commit('setProjects', respo.data.data);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async loadProjectDetails({ commit }, applicantsId) {
    commit('loadingStart', null);
    await this._vm.$http
      .get(`super/projects-agreed/${applicantsId}`)
      .then(respo => {
        if (respo.status === 200) {
          commit('setProjectDetails', respo.data.data.content);
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
  async changeProjectStatus({ commit }, data) {
    commit('loadingStart', null);
    await this._vm.$http
      .put(`super/projects/${data.id}/status`, data)
      .then(respo => {
        if (respo.status === 200) {
          commit('setProjectDetails', respo.data.data.content);
          commit('successMessage', 'change_status_successfully');
          commit('loadingFinish');
        }
      })
      .catch(error => {
        commit('loadingFinish');
        throw error;
      });
  },
};
